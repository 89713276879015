.coupon-code-bg {
  background: linear-gradient(135deg, #7158fe, #9d4de6);
}

.coupon_name {
  writing-mode: vertical-rl;
  transform: rotate(-0.5turn);
  background: linear-gradient(135deg, #7158fe, #9d4de6);
  color: white;
}
