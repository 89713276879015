.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
  border: 1px solid transparent !important;
}

.image-gallery-thumbnail {
  border: 1px solid transparent !important;
}

.image-gallery-thumbnail:hover {
  border: 1px transparent solid !important;
}

.image-gallery-thumbnails-wrapper.bottom
  .image-gallery-thumbnails
  .image-gallery-thumbnails-container
  .image-gallery-thumbnail {
  border: 1px solid transparent;
}

.image-gallery-content.bottom.fullscreen
  .image-gallery-thumbnails-wrapper.bottom,
.app-image-galleryWaft
  .image-gallery-content.bottom.fullscreen
  .image-gallery-slide-wrapper.bottom {
  border: none;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  margin: 0;
  height: 80px;
  width: 100%;
}

.image-gallery-content.bottom
  .image-gallery-thumbnails-wrapper.bottom
  .image-gallery-thumbnails
  .image-gallery-thumbnails-container
  .image-gallery-thumbnail
  .image-gallery-thumbnail-inner
  .image-gallery-thumbnail-image {
  height: 80px;
  object-fit: contain;
  background-color: #ffffff;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  max-height: 100%;
  margin: 0 auto;
  width: auto;
}

.image-gallery-content
  .image-gallery-thumbnails-wrapper.bottom
  .image-gallery-thumbnails
  .image-gallery-thumbnails-container
  .image-gallery-thumbnail
  .image-gallery-thumbnail-image {
  height: 100px;
  object-fit: contain;
}

/*Styles for login drawer*/
.checkmark__container__success {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.checkmark__circle__success {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: white;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.checkmark__success {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #22d056;
  animation:
    fillSuccess 0.4s ease-in-out 0.4s forwards,
    scaleSuccess 0.3s ease-in-out 0.9s both;
}
.checkmark__check__success {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: strokeSuccess 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
@keyframes strokeSuccess {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scaleSuccess {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fillSuccess {
  100% {
    box-shadow: inset 0px 0px 0px 30px #22d056;
  }
}

.add-to-cart-loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #092c56 #092c56 transparent transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.add-to-cart-loader::after,
.add-to-cart-loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent transparent #000 #000;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

/*add to cart failure*/
.checkmark__failure {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #092c56;
  animation:
    fillFailure 0.4s ease-in-out 0.4s forwards,
    scaleFailure 0.3s ease-in-out 0.9s both;
}

.checkmark_circle__failure {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #092c56;
  fill: none;
  animation: strokeFailure 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark_check__failure {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: strokeFailure 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes strokeFailure {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scaleFailure {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fillFailure {
  100% {
    box-shadow: inset 0px 0px 0px 30px #092c56;
  }
}
.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left
  .image-gallery-thumbnails
  .image-gallery-thumbnail,
.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right
  .image-gallery-thumbnails
  .image-gallery-thumbnail {
  padding: 2px;
}

.image-gallery-thumbnail {
  width: 100px;
}

.aspect-portrait {
  aspect-ratio: 672 / 840;
}
.aspect-landscape {
  aspect-ratio: 400 / 300;
}

@media screen and (max-width: 767px) {
  .image-gallery-thumbnails {
    display: none;
  }
  .image-gallery-slide-wrapper.image-gallery-thumbnails-left,
  .image-gallery-slide-wrapper.image-gallery-thumbnails-right {
    width: 100%;
  }
}
