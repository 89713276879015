.cat-title-shadow {
  text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.76);
}
.addressLoadingContainer {
  font-family: sans-serif;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  @apply py-3;
}

.dualRingSpinnerAddress {
  display: inline-block;
  position: relative;
  @apply rounded-full bg-white shadow-lg;
}
.dualRingSpinnerAddress:after {
  content: " ";
  display: block;
  width: 80px;
  height: 80px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #092c56;
  border-color: #092c56 transparent #092c56 transparent;
  animation: dualRingSpinnerAddress 1.5s linear infinite;
}
@keyframes dualRingSpinnerAddress {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.addressLogoImg {
  position: absolute;
  /* background-color: white; */
  @apply w-8 h-8;
}
