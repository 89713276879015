.brand_card_bg {
  background-image: linear-gradient(
    to right bottom,
    #e8e8e8,
    #fefefe,
    #ffffff,
    #ffffff,
    #ffffff
  );
}
